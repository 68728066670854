import { Button, Card, TextField, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from 'react-router-dom';

import CheckButton from "react-validation/build/button";
import Form from "react-validation/build/form";

import { login } from "../../actions/auth";
import { clearMessage } from "../../actions/message";
import './css/Login.css';
import TokenService from "../../services/token-service";
import { httpHelpers } from "../../services/httpHelpers";
import { Helmet } from "react-helmet-async";

const required = (value) => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                This field is required!
            </div>
        );
    }
};

const Login = ({ role, logout }) => {

    if (role) {
        console.log("Login", role);
        logout();
    }
    let navigate = useNavigate();
    const form = useRef();
    const checkBtn = useRef();

    const [username, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [showError, setShowError] = useState(true);
    const [cantConnect, setCantConnect] = useState("");
    const { message, types } = useSelector(state => state.message);

    const dispatch = useDispatch();
    const api = httpHelpers()

    const onChangeUserName = (e) => {
        const un = e.target.value;
        // const firstTwo = un.substring(0, 2).toUpperCase();
        // const restOfString = un.substring(2);  // Get the rest of the string
        // const username = firstTwo + restOfString;
        setUserName(un.toUpperCase());
    };

    const onChangePassword = (e) => {
        const password = e.target.value;
        setPassword(password);
    };

    let getNotificationMessage = "alpha/getGlobalProperty/agentNotificationMessage";

    const fetchNotificationMessage = () => {
        api
            .get(`${getNotificationMessage}`)
            .then(res => {
                console.log("get notification message res", res);
                if (res && res.data) {
                    let notificationMessage = res.data;
                    navigate("/home", { state: { notificationMessage } });
                } else {
                    // setNotificationMessage(res);
                    navigate("/home");
                }
            })

            .catch(err => {
                console.log("error error", err);
                if (err) {
                    if (err.data) {
                        if (err.data.status && err.data.status === 401) {
                            logout();
                        }
                    } else if (err.response) {
                        if (err.response.status && err.response.status === 401) {
                            logout();
                        }
                    }
                }
            });
    };

    const handleLogin = (e) => {
        setCantConnect("");
        e.preventDefault();
        setLoading(true);
        form.current.validateAll();

        if (checkBtn.current.context._errors.length === 0) {
            dispatch(login(username, password))
                .then((data) => {
                    console.log("log data", data);
                    if (data.status === 401) {
                        setLoading(false);
                        setCantConnect("Unauthorized. Please check Id and Password.");
                    } else if (data && data.accessToken === null && data.accountStatus && data.accountStatus.includes("NEW")) {
                        navigate("/changepassword");
                    } else if (data && data.accessToken && data.accountStatus && data.accountStatus.includes("ACTIVE")) {
                        TokenService.setUser(data);
                        // navigate("/home");
                        fetchNotificationMessage();
                    } else {
                        setLoading(false);
                    }
                })
                .catch(() => {
                    console.log("incat", "user");
                    setCantConnect("Connection Time Out.");
                    setLoading(false);
                });
        } else {
            console.log("inel", "user");
            setLoading(false);
        }
    };

    return (
        <div>
            <div style={{ marginTop: "60px", fontSize: "130px", color: "#E8E8E8", fontWeight: "bold" }}>
                <label>C247</label>
            </div>
            <div className="label-welcome">
                <div>
                    Welcome to Cric247
                </div>
                <div>
                    Sign In
                </div>
                <div>
                    -
                </div>
            </div>
            <div className="auth-card-div">
                <Form onSubmit={handleLogin} ref={form} style={{ width: "300px", marginTop: "10px" }}>
                    {/* <Card className="auth-card" variant="outlined" square="true"> */}
                    <TextField
                        fullWidth={true}
                        label="User Name"
                        variant="outlined"
                        type="text"
                        value={username}
                        onChange={onChangeUserName}
                        required
                        style={{ background: "white" }}
                        placeholder="User Name"
                    />
                    <br /><br />
                    <TextField
                        fullWidth={true}
                        label="Password"
                        variant="outlined"
                        type="password"
                        onChange={onChangePassword}
                        required
                        style={{ background: "white" }}
                        placeholder="Password"
                    />

                    <br /><br />
                    <Button type="submit" size="large" variant="contained" disabled={loading} style={{ display: "flex", width: "100%", background: "#3963af" }}>
                        {loading && (
                            <span className="spinner-border spinner-border-sm"></span>
                        )}
                        Login
                    </Button>

                    {message && (
                        <div className="form-group">
                            <div className={types === "CHANGE_PASSWORD_SUCCESS" ? "alert alert-success" : "alert alert-danger"} role="alert">
                                {message}
                            </div>
                        </div>
                    )}
                    {cantConnect && (
                        <div className="form-group" style={{ marginTop: 5 }}>
                            <div className="alert alert-danger" role="alert">
                                {cantConnect}
                            </div>
                        </div>
                    )}
                    <CheckButton style={{ display: "none" }} ref={checkBtn} />

                    {/* </Card> */}
                </Form>
            </div>
            <div style={{ marginTop: "10px", fontSize: "12px" }}>
                Cric247 &copy; 2024
            </div>
        </div>
        // <div className="login-root">
        //   <div className="login-page">
        //     <div className="login-card">
        //       <div className="left-side"></div>
        //       <div className="right-side">
        //         <div className="auth-card-text">
        //           <Typography variant={"h4"}>
        //             Cric 247
        //           </Typography>
        //         </div>
        //         <div className="auth-card-div">
        //           <Form onSubmit={handleLogin} ref={form}>
        //             <Card className="auth-card" variant="outlined" square="true">
        //               <TextField
        //                 fullWidth={true}
        //                 variant="outlined"
        //                 type="text"
        //                 onChange={onChangeUserName}
        //                 required
        //               />
        //               <br /><br />
        //               <TextField
        //                 fullWidth={true}
        //                 label="Password"
        //                 variant="outlined"
        //                 type="password"
        //                 onChange={onChangePassword}
        //                 required
        //               />

        //               <br /><br />
        //               <Button type="submit" size="large" variant="contained" disabled={loading} style={{ display: "flex", width: "100%", background: "#060c11" }}>
        //                 {loading && (
        //                   <span className="spinner-border spinner-border-sm"></span>
        //                 )}
        //                 Login
        //               </Button>

        //               {message && (
        //                 <div className="form-group">
        //                   <div className={types === "CHANGE_PASSWORD_SUCCESS" ? "alert alert-success" : "alert alert-danger"} role="alert">
        //                     {message}
        //                   </div>
        //                 </div>
        //               )}
        //               {cantConnect && (
        //                 <div className="form-group" style={{ marginTop: 5 }}>
        //                   <div className="alert alert-danger" role="alert">
        //                     {cantConnect}
        //                   </div>
        //                 </div>
        //               )}
        //               <CheckButton style={{ display: "none" }} ref={checkBtn} />

        //             </Card>
        //           </Form>
        //         </div>
        //       </div>
        //     </div>
        //   </div>
        // </div>
    );
};

export default Login;
