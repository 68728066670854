import React from 'react';
import '../css/BetSlip.css';
import BetSlipMarketPositionRunners from './BetSlipMarketPositionRunners';

const BetSlipMarketPosition = ({ headerTitle, detailContent, isSmallScreen, extraLeftMargin }) => {
    return (
        <div>
            <div className="header-market-position-container">
                <div className="header">
                    Market Position
                </div>
                <div className='market-separator-below-header'></div>
            </div>
            <div className="market-position-runner-position-container">
                <BetSlipMarketPositionRunners headerTitle={"RUNNER"} runnerPositionOne={"Lucknow Falcons"} runnerPositionTwo={"Meerut Mavericks"} />
                <BetSlipMarketPositionRunners headerTitle={"POSITION"} runnerPositionOne={"-221085.07"} runnerPositionTwo={"-474006.14"} />
            </div>
        </div>
    );
}

export default BetSlipMarketPosition;
