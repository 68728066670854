export const DEPOSIT_COINS = "deposit_coins";
export const WITHDRAW_COINS = "withdraw_coins";
export const DEPOSIT_CASH = "deposit_cash";
export const WITHDRAW_CASH = "withdraw_cash";
export const AGENT_BANNER_MESSAGE = "agentBannerMessage";
export const AGENT_NOTIFICATION_MESSAGE = "agentNotificationMessage";
export const USER_BANNER_MESSAGE = "userBannerMessage";
export const USER_NOTIFICATION_MESSAGE = "userNotificationMessage";
export const MATCH_STATUS_LIVE = "LIVE";
export const MATCH_STATUS_UPCOMING = "UPCOMING";
export const CRICKET = "cricket";
export const TENNIS = "tennis";
export const FOOTBALL = "football";
export const LIVE_CASINO = "live_casino";
export const VIRTUAL_CASINO = "virtual_casino";
export const LIVE_CASINO_STRING = "Live Casino";
export const VIRTUAL_CASINO_STRING = "Virtual Casino";
export const MARKET_STATUS_BLOCKEDUP = "BLOCKEDUP";
export const MARKET_STATUS_BLOCKED = "BLOCKED";
export const MARKET_STATUS_UNBLOCKED = "UNBLOCKED";
export const MATCH_ODDS = "Match Odds";
export const TOSS_ODDS = "Toss Odds";
export const BOOKMAKER = "Bookmaker";
export const FANCY_BET = "Fancy Bet";
export const MARKET_MATCH_ODDS = "matchodds";
export const MARKET_BOOKMAKER = "bookmaker";
export const MARKET_TOSS_ODDS = "tossodds";
export const MARKET_FANCY_BET = "session";