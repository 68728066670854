import React, { useState, useRef, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

import { changePassword, logout, changePasswordProfile } from "../../actions/auth";
import "../../index.css"
import { Navigate, useNavigate } from 'react-router-dom';
import TokenService from "../../services/token-service";
import { clearMessage } from "../../actions/message";
import { Card } from "@mui/material";
import "../../css/Signup.css";
import TableTitle from "./customized/TableTitle";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const vpassword = (value) => {
  if (value.length < 6 || value.length > 40) {
    return (
      <div className="alert alert-danger" role="alert">
        The password must be between 6 and 40 characters.
      </div>
    );
  }
};
const CricChangePassword = ({ role, logout }) => {
  console.log("changepassword", role);
  const form = useRef();
  const checkBtn = useRef();

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [successful, setSuccessful] = useState(false);
  const [showError, setShowError] = useState(true);

  const { message } = useSelector(state => state.message);
  const dispatch = useDispatch();
  let navigate = useNavigate();

  useEffect(() => {
    dispatch(clearMessage());
  }, []);

  const onChangeOldPassword = (e) => {
    const password = e.target.value;
    setOldPassword(password);
  };

  const [input, setInput] = useState({
    newPassword: '',
    confirmNewPassword: ''
  });

  const [error, setError] = useState({
    newPassword: '',
    confirmNewPassword: ''
  });

  const onInputChange = e => {
    const { name, value } = e.target;
    setInput(prev => ({
      ...prev,
      [name]: value
    }));
    validateInput(e);
  }

  const validateInput = e => {
    let { name, value } = e.target;
    setError(prev => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "newPassword":
          if (input.confirmNewPassword && value !== input.confirmNewPassword) {
            stateObj[name] = "Password and Confirm Password does not match.";
          } else if (input.confirmNewPassword && value === input.confirmNewPassword) {
            stateObj[name] = "";
          } else {
            stateObj[name] = input.confirmNewPassword ? "" : error.confirmNewPassword;
          }
          setNewPassword(value)
          break;

        case "confirmNewPassword":
          if (input.newPassword && value !== input.newPassword) {
            stateObj[name] = "Password and Confirm Password does not match.";
          } else if (input.newPassword && value === input.newPassword) {
            stateObj[name] = "";
          } else {
            stateObj[name] = input.newPassword ? "" : error.newPassword;
          }
          setConfirmNewPassword(value)
          break;
        default:
          break;
      }
      return stateObj;
    });
  }

  const handleChangePassword = (e) => {
    e.preventDefault();
    setSuccessful(false);
    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      const user = TokenService.getUser();
      console.log("changepassword user", user);
      if (user && user.accountStatus === "ACTIVE") {
        dispatch(changePasswordProfile(oldPassword, newPassword, role))
          .then((data) => {
            console.log("cp profile data:", data);
            if (data.status === 401) {
              if (data.data === "Wrong password") {
                setSuccessful(false);
              } else {
                logout();
              }
            } else {
              if (data.status === 200) {
                // logOut(role);
                setSuccessful(true);
              } else {
                setSuccessful(false);
              }
            }
          })
          .catch(() => {
            setSuccessful(false);
          });
      } else {
        const userId = user.userId;
        dispatch(changePassword(oldPassword, newPassword, userId, role))
          .then((data) => {
            console.log("cp data:", data);
            if (data.status === 401) {
              if (data.data === "Wrong password") {
                setSuccessful(false);
              } else {
                logout();
              }
            } else {
              if (data.status === 200) {
                // logOut(role);
                setSuccessful(true);
                // navigate("/dashboard");
              } else {
                setSuccessful(false);
              }
            }
          })
          .catch(() => {
            setSuccessful(false);
          });
      }
    }
  };

  return (
    <div style={{ background: "white" }}>
      {!successful && <div>
        {/* <label className="cric-register-header">Change Password</label> */}
        <TableTitle
          text="Change Password"
          color="#ffffff"
          fontSize="24px"
          textAlign="center"
          width="100%"
          height="52px"
          marginLeft="0px"
          paddingLeft="10px"
        />
      </div>}
      {!successful && (<div className="auth-card-div">
        <Form onSubmit={handleChangePassword} ref={form} style={{ width: "100%" }}>
          <Card className="auth-card" variant="outlined">
            <div>
              <div className="form-group">
                <div className="cric-form-label-input-container">
                  <div className="cric-div-label-form">
                    <label className="label-form" htmlFor="oldPassword">Old Password</label>
                  </div>
                  <div className="cric-div-input-form">
                    <Input
                      type="password"
                      className="form-control"
                      name="oldPassword"
                      value={oldPassword}
                      onChange={onChangeOldPassword}
                      validations={!successful && [required, vpassword]}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="cric-form-label-input-container">
                  <div className="cric-div-label-form">
                    <label className="label-form" htmlFor="newPassword">New Password</label>
                  </div>
                  <div className="cric-div-input-form">
                    <Input
                      type="password"
                      className="form-control"
                      name="newPassword"
                      value={newPassword}
                      onChange={onInputChange}
                      onBlur={validateInput}
                      validations={!successful && [required, vpassword]}
                    />
                  </div>
                  {error.newPassword && <div className="alert alert-danger" role="alert">{error.newPassword}</div>}
                </div>
              </div>
              <div className="form-group">
                <div className="cric-form-label-input-container">
                  <div className="cric-div-label-form">
                    <label className="label-form" htmlFor="confirmNewPassword">Confirm New Password</label>
                  </div>
                  <div className="cric-div-input-form">
                    <Input
                      type="password"
                      className="form-control"
                      name="confirmNewPassword"
                      value={confirmNewPassword}
                      onChange={onInputChange}
                      onBlur={validateInput}
                      validations={!successful && [required, vpassword]}
                    />
                  </div>
                  {error.confirmNewPassword && <div className="alert alert-danger" role="alert">{error.confirmNewPassword}</div>}
                </div>
              </div>
              <div className="form-group" style={{ marginTop: "30px" }}>
                <button className="board-buttons board-buttons-nav-bar-dark-small">Submit</button>
              </div>
            </div>


            <CheckButton style={{ display: "none" }} ref={checkBtn} />
          </Card>
        </Form>
      </div>)}

      {successful && (<div className="form-group">
        <div className={successful ? "alert alert-success" : "alert alert-danger"} role="alert" style={{ marginTop: "20px" }}>
          Password Changed Successfully!
        </div>
      </div>)}
      {!successful && message && (
        <div className="form-group">
          <div className="alert alert-danger" role="alert">
            {message}
          </div>
        </div>
      )}
    </div>
  );
};

export default CricChangePassword;