import { useState, useEffect } from "react";
import { httpHelpers } from "../../../services/httpHelpers";
import { marketTable } from "../tables/Columns";
import MarketTable from "../tables/MarketTable";
import TableTitle from "../customized/TableTitle";
import { useDispatch, useSelector } from "react-redux";
import { clearMessage } from "../../../actions/message";
import { SET_MESSAGE } from "../../../actions/types";
import { MARKET_STATUS_BLOCKED, MARKET_STATUS_UNBLOCKED } from "../common/Constants";
import TokenService from "../../../services/token-service";
import '../css/BetSlip.css';
import HeaderDetailComponent from "./BetSlipBetHolder";
import BetSlipWinnerTab from "./BetSlipWinnerTab";
import CustomizedDropDownMenu from "../customized/CustomizedDropDownMenu";
import { MATCH_ODDS, BOOKMAKER, FANCY_BET } from "../common/Constants";
import BetSlipMarketPosition from "./BetSlipMarketPosition";
import BetSlipStyledPaginationTable from "./BetSlipStylePaginationTable";

export default function BetSlipMatch({ role, logout, child, open, isSmallScreen }) {

    const href = window.location.href;
    let slipType = FANCY_BET;
    if (href.includes("betslipmatch")) {
        slipType = MATCH_ODDS;
    } else if (href.includes("betslipbookmaker")) {
        slipType = BOOKMAKER;
    }

    const [betSlipType, setBetSlipType] = useState(slipType);
    const user = TokenService.getUser();
    let userId = user.userId;

    if (child)
        userId = child.userId;
    const [successMessage, setSuccessMessage] = useState();
    const [successful, setSuccessful] = useState(false);
    const { message } = useSelector(state => state.message);
    const dispatch = useDispatch();
    let getMarkets = "beta/getSupportedSports?userId=";
    const api = httpHelpers();
    const [markets, setMarkets] = useState([]);

    const fetchMarkets = () => {
        api
            .get(`${getMarkets}` + userId)
            .then(res => {
                console.log("get markets res", res);
                if (res && res.data) {
                    setMarkets(res.data);
                } else {
                    setMarkets([]);
                }
            })

            .catch(err => {
                console.log("error error", err);
                if (err) {
                    if (err.data) {
                        if (err.data.status && err.data.status === 401) {
                            logout();
                        }
                    } else if (err.response) {
                        if (err.response.status && err.response.status === 401) {
                            logout();
                        }
                    }
                }
            });
    };

    // useEffect(() => {
    //     dispatch(clearMessage());
    //     fetchMarkets();
    // }, []);

    // useEffect(() => {
    //     fetchMarkets();
    // }, [successful]);

    const handleMarket = (sportType, state) => {
        setSuccessful(false);
        dispatch(clearMessage());
        console.log("handle market state", sportType, state);
        let blockUnblock = MARKET_STATUS_BLOCKED;
        let blockUnblockUrl = "beta/blockSport?sportType=";
        if (state) {
            blockUnblock = MARKET_STATUS_UNBLOCKED;
            blockUnblockUrl = "beta/unblockSport?sportType=";
        }
        api
            .get(`${blockUnblockUrl}` + sportType + "&userId=" + userId)
            .then(res => {
                console.log("block markets res", res);
                if (res && res.status === 200) {
                    setSuccessMessage(sportType + " " + blockUnblock + " Successfully!");
                    setSuccessful(true);
                } else {
                    setSuccessful(false);
                }
            })

            .catch(err => {
                console.log("error error", err);
                if (err) {
                    if (err.data) {
                        if (err.data.status && err.data.status === 401) {
                            logout();
                        }
                    } else if (err.response) {
                        if (err.response.status && err.response.status === 401) {
                            logout();
                        }
                    }
                }
            });
    };

    return (
        <div className="bet-slip-root">
            <div className="bet-slip-holder-container">
                <HeaderDetailComponent
                    headerTitle="Total Bets"
                    detailContent="1929"
                />
                <HeaderDetailComponent
                    headerTitle="Settled Bets"
                    detailContent="1929"
                />
                <HeaderDetailComponent
                    headerTitle="Unsettled Bets"
                    detailContent="0"
                />
                <HeaderDetailComponent
                    headerTitle="Reverted Bets"
                    detailContent="0"
                />
            </div>
            <div className="bet-slip-betsman-dropdown">
                <div className="bet-slip-users-dropdown">
                    <CustomizedDropDownMenu />
                </div>
                {(betSlipType === MATCH_ODDS || betSlipType === FANCY_BET) && <div className="bet-slip-winner-toss-dropdown">
                    <CustomizedDropDownMenu />
                </div>}
            </div>
            {betSlipType === BOOKMAKER && <div className="bet-slip-tab-container">
                <BetSlipWinnerTab open={open} isSmallScreen={isSmallScreen} extraLeftMargin={true} />
            </div>}
            {betSlipType === MATCH_ODDS && <div className="bet-slip-market-position-container" style={{ marginLeft: "5px" }}>
                <BetSlipMarketPosition isSmallScreen={isSmallScreen} extraLeftMargin={true} />
            </div>}
            {(betSlipType === MATCH_ODDS || betSlipType === FANCY_BET) && <div>
                <div className="header-bet-slip-table-container">
                    <div className="header">
                        Bet Slips
                    </div>
                    <div className='market-separator-below-header'></div>
                </div>
                {/* <BetSlipTable open={open} isSmallScreen={isSmallScreen} /> */}
                <BetSlipStyledPaginationTable open={open} isSmallScreen={isSmallScreen} />
            </div>}
        </div>
    );
};
