import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { httpHelpers } from "../../services/httpHelpers";
// import "../App.css";
import "./style/Video.css";
import "./style/CricketBattersBowlersTable.css";
import { clearMessage } from '../../actions/message';
import { useDispatch, useSelector } from "react-redux";
import CricketScoreBoard from './CricketScoreBoard';
import MatchBetPanel from './MatchBetPanel';
import {
    MATCH_ODDS,
    BOOKMAKER,
    TOSS_ODDS,
    FANCY_BET
} from "../cric247/common/Constants";
import AddSession from "../cric247/AddSession";

export default function MatchScreenCricket({ role, logout, open, isSmallScreen }) {

    const { sport, id } = useParams();
    const [successful, setSuccessful] = useState(false);
    const api = httpHelpers();
    const getOFBRates = "/beta/getMatchById?matchId=" + id;

    useEffect(() => {
        dispatch(clearMessage());
    }, []);

    const { message } = useSelector(state => state.message);
    const dispatch = useDispatch();

    // const matchOdds = "Match Odds";
    // const bookmaker = "Bookmaker";
    // const fancyBet = "Fancy Bet";
    // const [matchOddsList, setMatchOddsList] = useState();
    // const [bookmakerOddsList, setBookmakerOddsList] = useState();
    // const [fancyList, setFancyList] = useState();
    const [matchResponse, setMatchResponse] = useState();
    const [fancyContainer, setFancyContainer] = useState();
    const [matchOddsContainer, setMatchOddsContainer] = useState();
    const [bookmakerOddsContainer, setBookmakerContainer] = useState();
    const [matchDetails, setMatchDetails] = useState();
    const [tossContainer, setTossContainer] = useState();

    const fetchMatchMarkets = () => {
        api
            .get(`${getOFBRates}`)
            .then(res => {
                console.log("live markets", res);
                if (res && res.data) {
                    if (res.data.matchResponse) {
                        setMatchResponse(res.data.matchResponse);
                        if (res.data.matchResponse.matchStatus === "LIVE" || res.data.matchResponse.matchStatus === "UPCOMING") {
                            if (res.data.matchOddsContainer) {
                                setMatchOddsContainer(res.data.matchOddsContainer);
                            } else {
                                setMatchOddsContainer(null);
                            }
                            if (res.data.bookMakerOddsContainer) {
                                setBookmakerContainer(res.data.bookMakerOddsContainer);
                            } else {
                                setBookmakerContainer(null);
                            }
                            if (res.data.fancyContainer) {
                                setFancyContainer(res.data.fancyContainer);
                            } else {
                                setFancyContainer(null);
                            }
                            if (res.data.matchScore) {
                                setMatchDetails(null);
                                setMatchDetails(res.data.matchScore);
                            } else {
                                setMatchDetails(null);
                            }
                            if (res.data.tossContainer) {
                                setTossContainer(res.data.tossContainer);
                            } else {
                                setTossContainer(null);
                            }
                            // if (res.data.matchOddsList) {
                            //     setMatchOddsList(res.data.matchOddsList);
                            // } else {
                            //     setMatchOddsList(null);
                            // }
                            // if (res.data.bookmakerOddsList) {
                            //     setBookmakerOddsList(res.data.bookmakerOddsList);
                            // } else {
                            //     setBookmakerOddsList(null);
                            // }
                            // if (res.data.fancyList) {
                            //     setFancyList(res.data.fancyList);
                            // } else {
                            //     setFancyList(null);
                            // }
                            // if (res.data.matchScore) {
                            //     setMatchDetails(null);
                            //     setMatchDetails(res.data.matchScore);
                            // } else {
                            //     setMatchDetails(null);
                            // }
                        } else {
                            setMatchOddsContainer(null);
                            setBookmakerContainer(null);
                            setFancyContainer(null);
                            setMatchDetails(null);
                            setTossContainer(null);
                        }
                    } else {
                        setMatchResponse(null);
                    }
                }
            })
            .catch(err => {
                console.log("error error", err);
                if (err) {
                    if (err.data) {
                        if (err.data.status && err.data.status === 401) {
                            logout();
                        }
                    } else if (err.response) {
                        if (err.response.status && err.response.status === 401) {
                            logout();
                        }
                    }
                }
            });
    };

    // const fetchMatchMarkets = async () => {
    //     try {
    //         // const response = await api.customFetch("http://43.204.11.211:8080/cricket/getMatchBets?matchId=" + id);
    //         // const response = await api.customFetch("http://192.168.1.12:8090/getMatchDetails?matchId=9");
    //         const response = await api.customFetch("http://192.168.1.7:8080/alpha/getOFBRates?matchId=" + id);
    //         // const response = await api.customFetch("http://192.168.1.12:8080/getMatchBets?matchId=32902230");
    //         // console.log("fetch market res", response);
    //         if (response && response.status) {
    //             if (response.status === 200) {
    //                 const data = await response.json();
    //                 console.log("fetch market data 1", data.matchScore);
    //                 console.log("fetch market data 2", data);
    //                 if (data.matchOddsList) {
    //                     setMatchOddsList(data.matchOddsList);
    //                 } else {
    //                     setMatchOddsList(null);
    //                 }
    //                 if (data.bookmakerOddsList) {
    //                     setBookmakerOddsList(data.bookmakerOddsList);
    //                 } else {
    //                     setBookmakerOddsList(null);
    //                 }
    //                 if (data.fancyList) {
    //                     setFancyList(data.fancyList);
    //                 } else {
    //                     setFancyList(null);
    //                 }
    //                 if (data.matchScore) {
    //                     setMatchDetails(null);
    //                     setMatchDetails(data.matchScore);
    //                 } else {
    //                     setMatchDetails(null);
    //                 }
    //             }
    //         } else if (response === 401) {
    //             logout();
    //         }
    //         // if (response.status === 200) {
    //         //     setMatches(data);
    //         // } else if (response.status === 401) {
    //         //     logout();
    //         // } else {
    //         //     alert(data);
    //         // }
    //     } catch (error) {
    //         console.error("Error fetching cricket matches:", error);
    //     }
    // };

    useEffect(() => {
        fetchMatchMarkets();
        const intervalId = setInterval(() => {
            fetchMatchMarkets();
        }, 1000);

        // Clean up the interval when the component unmounts
        return () => clearInterval(intervalId);
    }, []);

    return (
        // <div>
        //     <div className="wrapper-cricket" style={{ marginTop: "49px" }}>
        //         {sport === "cricket" && matchDetails && <CricketScoreBoard matchId={id} matchDetails={matchDetails} />}
        //         <div className="bet-panel-root">
        //             <div className="bet-panel-header-1">
        //                 {matchOddsList && <MatchBetPanel marketType={MATCH_ODDS} oddsList={matchOddsList} />}
        //             </div>
        //             <div className="bet-panel-header-2">
        //                 {bookmakerOddsList && <MatchBetPanel marketType={BOOKMAKER} oddsList={bookmakerOddsList} />}
        //             </div>
        //             <div className="bet-panel-header-2">
        //                 {fancyList && <MatchBetPanel marketType={FANCY_BET} oddsList={fancyList} isFancy={true} />}
        //             </div>
        //         </div>
        //     </div>
        // </div>
        // <div className={open ? "live-match-container-nav-open" : "live-match-container"}>
        <div className="live-match-container">
            <div className="live-match-left-column">
                {/* <div className="live-streaming">
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/dQw4w9WgXcQ"
            title="Live Streaming"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div> */}
                <div className="live-match-screen-scoreboard-container">
                    {sport === "cricket" && matchDetails && <CricketScoreBoard matchId={id} matchDetails={matchDetails} />}
                    {sport === "cricket" && matchDetails ? <CricketScoreBoard matchId={id} matchDetails={matchDetails} /> : <label style={{ color: "#f8f8f8", fontSize: "14px" }}>{matchResponse && matchResponse.name}</label>}
                </div>
            </div>
            <div className="live-match-right-column">
                <div className="bet-panel-root">
                    <div className="bet-panel-header-1">
                        {tossContainer && tossContainer.marketList && <MatchBetPanel marketType={TOSS_ODDS} oddsList={tossContainer.marketList} userPosition={tossContainer.userPosition} isSmallScreen={isSmallScreen} minBet={tossContainer.minBet} maxBet={tossContainer.maxBet} />}
                    </div>
                    <div className="bet-panel-header-1">
                        {matchOddsContainer && matchOddsContainer.marketList && <MatchBetPanel marketType={MATCH_ODDS} oddsList={matchOddsContainer.marketList} minLimit={matchOddsContainer} minBet={matchOddsContainer.minBet} maxBet={matchOddsContainer.maxBet} />}
                    </div>
                    <div className="bet-panel-header-2">
                        {bookmakerOddsContainer && bookmakerOddsContainer.marketList && <MatchBetPanel marketType={BOOKMAKER} oddsList={bookmakerOddsContainer.marketList} minBet={bookmakerOddsContainer.minBet} maxBet={bookmakerOddsContainer.maxBet} />}
                    </div>
                    {/* {role && role.includes("admin") && fancyContainer && <div style={{ marginTop: "1px", marginBottom: "1px" }} className="match-odds-betting-market-container">
                        <AddSession />
                    </div>} */}
                    <div className="bet-panel-header-2">
                        {fancyContainer && fancyContainer.marketList && <MatchBetPanel role={role} marketType={FANCY_BET} oddsList={fancyContainer.marketList} isFancy={true} userPosition={fancyContainer.userPosition} isSmallScreen={isSmallScreen} minBet={fancyContainer.minBet} maxBet={fancyContainer.maxBet} />}
                    </div>
                </div>
            </div>
        </div>
    );
};
