import '../parvati/style/MatchOdds.css';
import './css/CricMatchOdds.css';
import {
    MATCH_ODDS,
    BOOKMAKER,
    FANCY_BET,
    TIED_MATCH
} from '../parvati/common/constants';

export default function CricMarketHeaderFancy({ marketHeader, nationOne, nationTwo, marketType, isFancy, userPosition, minBet, maxBet }) {
    console.log("fancy userposition", userPosition);

    return (
        <div>
            <div>
                {/* <div className="bet-market-header-matched-amount-container">
                    <div className="bet-market-header-container">
                        <div className="bet-market-header">
                            {marketHeader}
                        </div>
                    </div>
                    <div className="matched-amount">
                        <label>Matched </label><label>{marketMatchedAmount}M</label>
                    </div>
                </div> */}
                <div className="match-odds-market-sub-header-container-fancy">
                    <div className="cric-bet-market-header">
                        {marketHeader}
                    </div>
                    <div className="fancy-user-position-container" style={{ color: userPosition && userPosition >= 0 ? "green" : "red" }}>
                        {userPosition}
                    </div>
                </div>
                <div className="match-odds-market-sub-header-container">
                    {/* <div className="match-odds-market-sub-header-empty"></div> */}
                    <div className="min-max-amount-container-fancy">
                        <div className="min-max-amount-fancy">
                            <label>Min: {minBet} | Max: {maxBet}</label>
                        </div>
                    </div>
                    <div className="back-header-container-fancy">
                        <div className="back-header-fancy">
                            {nationOne}
                        </div>
                    </div>
                    <div className="lay-header-container-fancy">
                        <div className="lay-header-fancy">
                            {nationTwo}
                        </div>
                    </div>
                    <div className="position-header-container">
                        <div className="position-header">
                            {"Pos NO"}
                        </div>
                    </div>
                    <div className="position-header-container">
                        <div className="position-header">
                            {"Pos Yes"}
                        </div>
                    </div>
                    <div className="fancy-actions-container">
                        <div className="fancy-actions">
                            {/* {"Actions"} */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}