// src/DynamicTable.js
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../css/DynamicTable.css';
import '../css/BetHistoryTable.css';
import '../css/AgentTablePagination.css';
import { DEPOSIT_COINS, WITHDRAW_COINS } from '../common/Constants';
import {
    MATCH_ODDS,
    BOOKMAKER,
    TOSS_ODDS,
    FANCY_BET,
    MARKET_MATCH_ODDS,
    MARKET_BOOKMAKER,
    MARKET_TOSS_ODDS,
    MARKET_FANCY_BET
} from "../../cric247/common/Constants";

const BetHistoryTable = ({ columns, data, tableHeader }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(1000);

    // useEffect(() => {
    //     const handleResize = () => {
    //         if (window.innerWidth <= 600) {
    //             setItemsPerPage(5);
    //         } else {
    //             setItemsPerPage(5);
    //         }
    //     };

    //     handleResize(); // Initial check
    //     window.addEventListener('resize', handleResize);

    //     return () => window.removeEventListener('resize', handleResize);
    // }, []);

    const totalPages = Math.ceil(data.length / itemsPerPage);

    const handlePageChange = (pageNumber) => {
        if (pageNumber >= 1 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
        }
    };

    const startIndex = (currentPage - 1) * itemsPerPage;
    const currentData = data.slice(startIndex, startIndex + itemsPerPage);

    // Generate pagination buttons
    const pageNumbers = [];
    const visiblePages = 5;
    let startPage = Math.max(1, currentPage - Math.floor(visiblePages / 2));
    let endPage = Math.min(totalPages, startPage + visiblePages - 1);

    if (endPage - startPage + 1 < visiblePages) {
        startPage = Math.max(1, endPage - visiblePages + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
    }

    // let navigate = useNavigate();
    // const openLiveReport = (e, id, title) => {
    //     e.preventDefault();
    //     navigate(`/matchscreen/${sport}/${id}/${title}`);
    //     // navigate(`/livematchscreen/${sport}/${id}/${title}`);
    // };

    return (
        <div className="bet-history-table-root">
            {/* <div className="bet-history-table-container"> */}
            <table className="bet-history-table-custom">
                <thead>
                    <tr>
                        {columns.map((column, index) => (
                            <th className="bet-history-table-header-cell-grey" key={index}>{column}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {currentData.map((row, rowIndex) => (
                        <tr key={rowIndex} className="bet-history-table-row">
                            <td className="bet-history-table-custom-td">
                                {row.betDate}
                            </td>
                            <td className="bet-history-table-custom-td">
                                {row.matchName}
                            </td>
                            <td className="bet-history-table-custom-td">
                                {row.marketName}
                            </td>
                            <td className="bet-history-table-custom-td">
                                {row.betType === MARKET_MATCH_ODDS && MATCH_ODDS}
                                {row.betType === MARKET_BOOKMAKER && BOOKMAKER}
                                {row.betType === MARKET_TOSS_ODDS && TOSS_ODDS}
                                {row.betType === MARKET_FANCY_BET && FANCY_BET}
                            </td>
                            <td className="bet-history-table-custom-td">
                                {row.candidate}
                            </td>
                            <td className="bet-history-table-custom-td">
                            {row.betType === MARKET_FANCY_BET && row.sessionValue}
                            {row.betType !== MARKET_FANCY_BET && "-"}
                            </td>
                            <td className="bet-history-table-custom-td">
                                {row.rate}
                            </td>
                            <td className="bet-history-table-custom-td">
                                {row.amount.toFixed(1)}
                            </td>
                            {/* <td className="bet-history-table-custom-td">
                                {row.transactionType === DEPOSIT_COINS && "-"}
                                {row.transactionType === WITHDRAW_COINS && row.amount.toFixed(1)}
                            </td>
                            <td className="bet-history-table-custom-td">
                                {row.transactionType === DEPOSIT_COINS && row.amount.toFixed(1)}
                                {row.transactionType === WITHDRAW_COINS && "-"}
                            </td>
                            <td className="bet-history-table-custom-td">
                                0
                            </td>
                            <td className="bet-history-table-custom-td">
                                0
                            </td>
                            <td className="bet-history-table-custom-td">
                                {row.toUserBalance.toFixed(1)}
                            </td> */}
                        </tr>
                    ))}
                </tbody>
            </table>
            {/* </div> */}
            {/* {currentData && <div className="pagination">
                <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    className="agent-table-pagination-prev-button"
                    disabled={currentPage === 1}
                >
                    Previous
                </button>
                {startPage > 1 && <span className="pagination-ellipsis">...</span>}
                {pageNumbers.map((page) => (
                    <button
                        key={page}
                        onClick={() => handlePageChange(page)}
                        className={`bet-history-table-pagination-button ${currentPage === page ? 'active' : ''}`}
                    >
                        {page}
                    </button>
                ))}
                {endPage < totalPages && <span className="pagination-ellipsis">...</span>}
                <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    className="agent-table-pagination-next-button"
                    disabled={currentPage === totalPages}
                >
                    Next
                </button>
            </div>} */}
        </div>
    );
};

export default BetHistoryTable;
