import { Outlet, useParams } from "react-router-dom";
import Breadcrumbs from "../../Breadcrumbs";

const MarqeeNavPath = ({ marqee, navPath, currentPage, visitedPaths, bannerMessage }) => {
    console.log("Marqee", bannerMessage);
    const { sport, id, title } = useParams();
    console.log("title title", title);
    var currentPage = "";
    var previousPath = "";
    console.log("visitedPaths", visitedPaths);
    console.log("visitedPaths", visitedPaths.length);

    if (visitedPaths) {
        if (visitedPaths.includes("/home")) {
            currentPage = "Home";
        }
        if (visitedPaths.includes("/showsm")) {
            currentPage = "SM";
        }
        if (visitedPaths.includes("/showsc")) {
            currentPage = "SC";
        }
        if (visitedPaths.includes("/showsst")) {
            currentPage = "SST";
        }
        if (visitedPaths.includes("/showst")) {
            currentPage = "Stockists";
        }
        if (visitedPaths.includes("/showagent")) {
            currentPage = "Agent";
        }
        if (visitedPaths.includes("/clients")) {
            currentPage = "CLIENTS";
        }
        if (visitedPaths.includes("/registersm")) {
            currentPage = "New Sub Manager";
        }
        if (visitedPaths.includes("/registersc")) {
            currentPage = "New Sub Company";
        }
        if (visitedPaths.includes("/registersst")) {
            currentPage = "New Super Stockist";
        }
        if (visitedPaths.includes("/registerst")) {
            currentPage = "New Stockist";
        }
        if (visitedPaths.includes("/registergent")) {
            currentPage = "New Agent";
        }
        if (visitedPaths.includes("/registeruser")) {
            currentPage = "Agent";
        }
        if (visitedPaths.includes("/receivecash")) {
            currentPage = "Agent";
        }
        if (visitedPaths.includes("/matchesdashboard")) {
            currentPage = "Matches";
        }
        // const lastVisitedPath = visitedPaths[visitedPaths.length - 1].replace("/", "");
        // previousPath = "Home / ";
        // if (lastVisitedPath.includes("userpassword")) {
        //     currentPage = "Change User Password";
        // } else if (lastVisitedPath.includes("password")) {
        //     currentPage = "Manage Password";
        // } else if (lastVisitedPath.includes("sports") || lastVisitedPath.includes("matches")) {
        //     currentPage = "Matches";
        // } else if (lastVisitedPath.includes("showagent")) {
        //     currentPage = "Agent";
        // } else if (lastVisitedPath.includes("showsc")) {
        //     currentPage = "SC";
        // } else if (lastVisitedPath.includes("showsst")) {
        //     currentPage = "SST";
        // } else if (lastVisitedPath.includes("showst")) {
        //     currentPage = "ST";
        // } else if (lastVisitedPath.includes("showuser")) {
        //     currentPage = "User";
        // } else if (lastVisitedPath.includes("registeragent")) {
        //     currentPage = "New Agent";
        // } else if (lastVisitedPath.includes("registersc")) {
        //     currentPage = "New Sub Company";
        // } else if (lastVisitedPath.includes("registersst")) {
        //     currentPage = "New Super Stockist";
        // } else if (lastVisitedPath.includes("registeruser")) {
        //     currentPage = "New User";
        // } else if (lastVisitedPath.includes("statement")) {
        //     currentPage = "Statement";
        // } else if (lastVisitedPath.includes("ledgers")) {
        //     currentPage = "My Ledgers";
        // } else if (lastVisitedPath.includes("icdashboard")) {
        //     currentPage = "Agent Dashboard";
        // } else if (lastVisitedPath.includes("clients")) {
        //     currentPage = "My Clients";
        // } else if (lastVisitedPath.includes("commissionlimits")) {
        //     currentPage = "Commission & Limits";
        // } else if (lastVisitedPath.includes("settings")) {
        //     currentPage = "Settings";
        // } else if (lastVisitedPath.includes("collectionreport")) {
        //     currentPage = "Collection Report";
        // } else if (lastVisitedPath.includes("eledger")) {
        //     currentPage = "Ledger";
        // } else if (lastVisitedPath.includes("eledger")) {
        //     currentPage = "Ledger";
        // } else if (lastVisitedPath.includes("update")) {
        //     currentPage = "Update";
        // } else {
        //     previousPath = "";
        //     currentPage = "Home";
        // }




        // for (var i = 0; i < visitedPaths.length; i++) {
        //     console.log("visitedPaths", visitedPaths[i]);
        //     var path = visitedPaths[i].replace("/", "");
        //     if (i != 0) {
        //         console.log(path);
        //         if (i == 0) {
        //             previousPath = "Home";
        //         } else {
        //             if (path.includes("home")) {
        //                 path = "Home";
        //             } else if (path.includes("password")) {
        //                 path = "Manage Password";
        //             } else if (path.includes("statement")) {
        //                 path = "Statement";
        //             } else if (path.includes("sports")) {
        //                 if (path.includes("live")) {
        //                     path = "Live";
        //                 } else if (path.includes("upcoming")) {
        //                     path = "Upcoming";
        //                 } else if (path.includes("past")) {
        //                     path = "Past";
        //                 }
        //             } else if (path.includes("show")) {
        //                 if (path.includes("sc")) {
        //                     path = "Sub Company";
        //                 } else if (path.includes("sst")) {
        //                     path = "Super Stockist";
        //                 } else if (path.includes("st")) {
        //                     path = "Stockist";
        //                 } else if (path.includes("agent")) {
        //                     path = "Agent";
        //                 } else if (path.includes("user")) {
        //                     path = "User";
        //                 }
        //             }

        //             if (i == visitedPaths.length - 1) {
        //                 if (path.includes("Live") || path.includes("Upcoming") || path.includes("Past")) {
        //                     currentPage = "Matches";
        //                 } else if (path.includes("Sub Company") || path.includes("Super Stockist") || path.includes("Stockist")
        //                     || path.includes("Agent")) {
        //                     currentPage = "Agent";
        //                 } else if (path.includes("User")) {
        //                     currentPage = "User";
        //                 } else {
        //                     currentPage = path;
        //                 }
        //             } else {
        //                 if (i == visitedPaths.length - 2) {
        //                     path = path + " /";
        //                 }

        //                 previousPath = previousPath + " / " + path;
        //             }
        //         }
        //     }
        // }
    }

    return (
        <div>
            <div style={{ width: "100%", position: "fixed", zIndex: "999", background: "#F6F6F6" }}>
            {/* <div style={{ width: "100%", background: "#F6F6F6" }}> */}
                <marquee bgcolor="#204b99"
                    direction="left" loop="">
                    <div style={{ paddingTop: "4px", paddingBottom: "4px", color: "white", fontSize: "16px" }}>
                        {bannerMessage}
                    </div>
                </marquee>
                <div className="nav-path-holder" style={{ paddingTop: "5px" }}>
                    <Breadcrumbs />
                </div>
                {/* <div className="nav-path-holder" style={{ paddingTop: "10px" }}>
                    <label className="nav-path-current-page">{currentPage}</label>
                    <div style={{ flex: "rows", paddingTop: "0px", paddingBottom: "0px" }}>
                        <label className="nav-path">{previousPath}</label>
                        {currentPage !== "Home" && !title && <label className="nav-path" style={{ fontWeight: "bold" }}>&nbsp;{currentPage}</label>}
                        {currentPage !== "Home" && title && <label className="nav-path">&nbsp;{currentPage}</label>}{title && <label style={{ fontWeight: "bold" }}>&nbsp;/&nbsp;{title}</label>}
                        {currentPage == "Home" && <label className="nav-path" style={{ fontWeight: "bold" }}>{currentPage}</label>}
                    </div>
                </div> */}
            </div>
        </div>
    );
};

export default MarqeeNavPath;