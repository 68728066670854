import React from 'react';
import PropTypes from 'prop-types';
import '../css/TableTitle.css';

const TableTitle = ({ text, color, fontSize, textAlign, width, height, marginLeft, marginRight, paddingLeft, marginBottom }) => {
    const titleStyle = {
        color: color || '#333',
        fontSize: fontSize || '28px',
        width: width || '100%',
        height: height || 'auto',
        marginLeft: marginLeft || '4px',
        marginRight: marginRight || '10px',
        marginBottom: marginBottom || '0px',
        paddingLeft: paddingLeft || '20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: textAlign === 'left' ? 'flex-start' :
                       textAlign === 'right' ? 'flex-end' : 'center',
    };

    return (
        <div className="table-title" style={titleStyle}>
            {text}
        </div>
    );
};

TableTitle.propTypes = {
    text: PropTypes.string.isRequired,
    color: PropTypes.string,
    fontSize: PropTypes.string,
    textAlign: PropTypes.oneOf(['left', 'center', 'right']),
    width: PropTypes.string,
    height: PropTypes.string,
    marginLeft: PropTypes.string,
    marginRight: PropTypes.string,
    paddingLeft: PropTypes.string,
};

export default TableTitle;