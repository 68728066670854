import "rsuite/dist/rsuite.min.css";
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import TableHeader from "./TableHeader";
import './css/LiveMatches.css';
import { useState } from "react";
import { Height } from "@material-ui/icons";
import TokenService from "../../services/token-service";
import TableTitle from "./customized/TableTitle";

export default function AgentDashboard({ role, logout, open, isSmallScreen }) {
    console.log("show", role);
    const { state } = useLocation();

    if (state === null) {
        logout();
    }

    const user = TokenService.getUser();
    const { child } = state ? state : null;
    console.log("agent dashboard", child);
    const [childType, setChildType] = useState(child ? child.entityType : "");
    let navigate = useNavigate();

    const openReceiveCash = (e) => {
        e.preventDefault();
        navigate(`/receivecash/${child.userId}`, { state: { child } });
    };

    const openPayCash = (e) => {
        e.preventDefault();
        navigate(`/paycash/${child.userId}`, { state: { child } });
    };

    const openLedger = (e) => {
        e.preventDefault();
        navigate('/eledger');
    };

    const openCashLedger = (e) => {
        e.preventDefault();
        // navigate(`/matchscreen/${sport}/${id}/${title}`);
        // navigate(`/livematchscreen/${sport}/${id}/${title}`);
        navigate(`/eledger/${child.userId}/${child.entityType}`, { state: { child } });
    };

    const openMatchLedger = (e) => {
        e.preventDefault();
        // navigate('/registergames');
    };

    const openDirectAgents = (e) => {
        e.preventDefault();
        navigate(`/directagents/${child.userId}/${child.entityType}`, { state: { child } });
    };

    const openDirectClients = (e) => {
        e.preventDefault();
        navigate(`/directclients/${child.userId}/${child.entityType}`, { state: { child } });
    };

    const openCoinHistory = (e) => {
        e.preventDefault();
        navigate(`/estatement/`, { state: { child } });
    };

    const openStatements = (e) => {
        e.preventDefault();
        // navigate(`/estatement/`, { state: { child } });
    };

    return (
        <div className="agent-dashboard-root">
            <div>
                {/* <TableHeader title={"Agent Dashboard"} /> */}
                <TableTitle
                    text="Agent Dashboard"
                    color="#ffffff"
                    fontSize="24px"
                    textAlign="left"
                    width={isSmallScreen ? "97.2%" : "99.2%" }
                    height="66px"
                />
            </div>
            <div style={{ paddingTop: "20px", paddingBottom: "20px", background: "white" }}>
                <div className="cric-agent-matches-dashboard-container">
                    <div className="cric-agent-matches-dashboard-buttons-container">
                        <div className="cric-agent-matches-dashboard-buttons-parent">
                            <button className="cric-board-buttons cric-board-agent-mathces-dashboard-buttons-large"
                                style={{ marginLeft: "5px" }}
                                onClick={(e) => openReceiveCash(e)}>
                                Receive Cash
                            </button>
                            <button className="cric-board-buttons cric-board-agent-mathces-dashboard-buttons-large"
                                style={{ marginLeft: "5px" }}
                                onClick={(e) => openPayCash(e)}>
                                Pay Cash
                            </button>
                        </div>
                        <div className="cric-agent-matches-dashboard-buttons-parent">
                            <button className="cric-board-buttons cric-board-agent-mathces-dashboard-buttons-large"
                                style={{ marginLeft: "5px" }}
                                onClick={(e) => openLedger(e)}>
                                Ledger
                            </button>
                            <button className="cric-board-buttons cric-board-agent-mathces-dashboard-buttons-large"
                                style={{ marginLeft: "5px" }}
                                onClick={(e) => openCashLedger(e)}>
                                Cash Ledger
                            </button>
                        </div>
                    </div>
                </div>
                <div className="cric-agent-matches-dashboard-container">
                    <div className="cric-agent-matches-dashboard-buttons-container">
                        <div className="cric-agent-matches-dashboard-buttons-parent">
                            <button className="cric-board-buttons cric-board-agent-mathces-dashboard-buttons-large"
                                style={{ marginLeft: "5px" }}
                                onClick={(e) => openMatchLedger(e)}>
                                Match Ledger
                            </button>
                            {childType !== "user" && <button className="cric-board-buttons cric-board-agent-mathces-dashboard-buttons-large"
                                style={{ marginLeft: "5px" }}
                                onClick={(e) => openDirectAgents(e)}>
                                Direct Agents
                            </button>}
                            {childType === "user" && <button className="cric-board-buttons cric-board-agent-mathces-dashboard-buttons-large"
                                style={{ marginLeft: "5px" }}
                                onClick={(e) => openCoinHistory(e)}>
                                Coin History
                            </button>}
                        </div>
                        <div className="cric-agent-matches-dashboard-buttons-parent">
                            {childType !== "user" && <button className="cric-board-buttons cric-board-agent-mathces-dashboard-buttons-large"
                                style={{ marginLeft: "5px" }}
                                onClick={(e) => openDirectClients(e)}>
                                Direct Clients
                            </button>}
                            {childType !== "user" && <button className="cric-board-buttons cric-board-agent-mathces-dashboard-buttons-large"
                                style={{ marginLeft: "5px" }}
                                onClick={(e) => openCoinHistory(e)}>
                                Coin History
                            </button>}
                            {childType === "user" && <button className="cric-board-buttons cric-board-agent-mathces-dashboard-buttons-large"
                                style={{ marginLeft: "5px" }}
                                onClick={(e) => openStatements(e)}>
                                Statements
                            </button>}
                            {childType === "user" && <button
                                style={{ marginLeft: "5px", background: "transparent", width: isSmallScreen ? "50%" : "51%" }} >
                            </button>}
                        </div>
                    </div>
                </div>
            </div>
            <div className="cric-agent-matches-dashboard-coins-rs-exposure-container">
                <div className="cric-agent-matches-dashboard-coins-rs-exposure">
                    <div>
                        {/* <TableHeader title={"Coin Balance"} /> */}
                        <TableTitle
                            text="Coin Balance"
                            color="#ffffff"
                            fontSize="24px"
                            textAlign="left"
                            width="99.2%"
                            height="60px"
                        />
                    </div>
                    <div className="cric-agent-matches-dashboard-coins-rs-exposure-value">
                        {user.balance}
                    </div>
                </div>
                <div className="cric-agent-matches-dashboard-coins-rs-exposure">
                    <div>
                        {/* <TableHeader title={child.entityType === "user" ? "Rs. Exposure" : "Unsettled Balance"} /> */}
                        <TableTitle
                            // text="Admin Settings"
                            text={child.entityType === "user" ? "Rs. Exposure" : "Unsettled Balance"}
                            color="#ffffff"
                            fontSize="24px"
                            textAlign="left"
                            width="99.2%"
                            height="60px"
                        />
                    </div>
                    <div className="cric-agent-matches-dashboard-coins-rs-exposure-value">
                        {child.entityType === "user" ? user.exposure : user.unsettledBalance}
                    </div>
                </div>
            </div>
        </div>
    );
};
