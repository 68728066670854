import '../parvati/style/MatchOdds.css';
import './css/CricMatchOdds.css';
import {
    MATCH_ODDS,
    BOOKMAKER,
    FANCY_BET,
    TIED_MATCH
} from '../parvati/common/constants';

export default function CricMarketHeader({ marketHeader, nationOne, nationTwo, marketType, isFancy, minBet, maxBet }) {

    return (
        <div>
            <div>
                {/* <div className="bet-market-header-matched-amount-container">
                    <div className="bet-market-header-container">
                        <div className="bet-market-header">
                            {marketHeader}
                        </div>
                    </div>
                    <div className="matched-amount">
                        <label>Matched </label><label>{marketMatchedAmount}M</label>
                    </div>
                </div> */}
                <div className="match-odds-market-sub-header-container">
                    {/* <div className="match-odds-market-sub-header-empty"></div> */}
                    <div className="min-max-amount-container">
                        {/* <div className="min-max-amount">
                            <label>Min/Max </label>{marketMinMaxAmount}
                        </div> */}
                        <div className="cric-bet-market-header">
                            {marketHeader}
                        </div>
                    </div>
                </div>
                <div className="match-odds-market-sub-header-container">
                    {/* <div className="match-odds-market-sub-header-empty"></div> */}
                    <div className="min-max-amount-container">
                        <div className="min-max-amount">
                            <label>Min: {minBet} | Max: {maxBet}</label>
                        </div>
                    </div>
                    <div className="back-header-container">
                        <div className="back-header">
                            {nationOne}
                        </div>
                    </div>
                    <div className="lay-header-container">
                        <div className="lay-header">
                            {nationTwo}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}