import {
  MdDashboard, MdManageAccounts, MdLiveTv,
  MdUpcoming, MdDone, MdPassword, MdPages, MdSettings,
  MdBlock,
  MdShare,
  MdPercent,
  MdSportsCricket,
  MdMoney,
  MdFactory,
  MdCalculate,
  MdBalance,
  MdBusiness,
  MdCasino,
  MdShop,
  MdShop2,
  MdLocalMall,
  MdBlockFlipped,
  MdSearch,
  MdLeaderboard
} from "react-icons/md";

export const sidebarDataAdmin = [
  { label: 'Dashboard', icon: <MdDashboard />, link: '/home' },
  // { label: 'Statement', icon: <MdPages />, link: '/statement' },
  {
    label: 'Manage',
    icon: <MdManageAccounts />,
    link: '/manage',
    subItems: [
      { label: 'SM', icon: <MdManageAccounts />, link: '/showsm' },
      { label: 'SC', icon: <MdManageAccounts />, link: '/showsc' },
      { label: 'SST', icon: <MdManageAccounts />, link: '/showsst' },
      { label: 'Stockists', icon: <MdManageAccounts />, link: '/showst' },
      { label: 'Agents', icon: <MdManageAccounts />, link: '/showagent' },
      // { label: 'Users', icon: <MdManageAccounts />, link: '/showuser' },
    ],
    // subItems: [
    //   { label: 'SC', icon: <MdManageAccounts/>, link: '/registersc' },
    //   { label: 'SST', icon: <MdManageAccounts/>, link: '/registersst' },
    //   { label: 'Stockists', icon: <MdManageAccounts/>, link: '/registerst' },
    //   { label: 'Agents', icon: <MdManageAccounts/>, link: '/registeragent' },
    //   { label: 'My Clients', icon: <MdManageAccounts/>, link: '/registeruser' },
    // ],
  },
  // {
  //   label: 'Manage Clients',
  //   icon: <MdManageAccounts/>,
  //   link: '/manage',
  //   subItems: [
  //     { label: 'My Clients', icon: <MdManageAccounts/>, link: '/reguseradmin' },
  //     { label: 'Blocked Clients', icon: <MdManageAccounts/>, link: '/stocklists' },
  //     { label: 'Commission & Limits', icon: <MdManageAccounts/>, link: '/agents' },
  //   ],
  // },
  // {
  //   label: 'Manage Ledgers',
  //   icon: <MdManageAccounts />,
  //   link: '/manage',
  //   subItems: [
  //     { label: 'Collection Report', icon: <MdManageAccounts />, link: '/sst' },
  //     { label: 'Company Len-Den', icon: <MdManageAccounts />, link: '/stocklists' },
  //     { label: 'In-Out', icon: <MdManageAccounts />, link: '/agents' },
  //     { label: 'P & L', icon: <MdManageAccounts />, link: '/agents' },
  //   ],
  // },
  // { label: 'Live Matches', icon: <MdLiveTv />, link: `/sports/${"live"}` },
  { label: 'Live Matches', icon: <MdSportsCricket />, link: 'matcheslist' },
  // { label: 'Upcoming Matches', icon: <MdUpcoming />, link: `/sports/${"upcoming"}` },
  { label: 'Completed Matches', icon: <MdSportsCricket />, link: 'compmatcheslist' },
  { label: 'Live Casino', icon: <MdCasino /> },
  { label: 'Virtual Casino', icon: <MdCasino /> },
  { label: 'Block Market', icon: <MdBlock />, link: 'blockmarket' },
  {
    label: 'Manage Clients',
    icon: <MdManageAccounts />,
    link: '/manageclients',
    subItems: [
      { label: 'My Clients', icon: <MdManageAccounts />, link: '/clients' },
      { label: 'Blocked Clients', icon: <MdBlock />, link: '/blockedclients' },
      { label: 'Commission & Limits', icon: <MdPercent />, link: '/commissionlimits' },
    ],
  },
  { label: 'Settings', icon: <MdSettings />, link: '/settings' },
  { label: 'Search Mole', icon: <MdSearch />, link: '/searchmole' },
  {
    label: 'Manage Ledgers',
    icon: <MdPages />,
    link: '/manage',
    subItems: [
      { label: 'Collection Report', icon: <MdCalculate />, link: '/collectionreport' },
      { label: 'Company Len-Den', icon: <MdBusiness />, link: '/eledger' },
      { label: 'In-Out', icon: <MdBalance />, link: '/estatement' },
      { label: 'P & L', icon: <MdMoney />, link: '/agents' },
    ],
  },
  { label: 'Change Password', icon: <MdPassword />, link: '/changemypassword' },
  // Add more sidebar items as needed
];