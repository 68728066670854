// src/DynamicTable.js
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../css/DynamicTable.css';
import '../css/MatchesTable.css';
import '../css/AgentTablePagination.css';
import Clock_Icon from '../../../assets/clock_icon.svg';

const MatchesTable = ({ columns, data, sport, liveMatches }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 600) {
                setItemsPerPage(5);
            } else {
                setItemsPerPage(5);
            }
        };

        handleResize(); // Initial check
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const totalPages = Math.ceil(data.length / itemsPerPage);

    const handlePageChange = (pageNumber) => {
        if (pageNumber >= 1 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
        }
    };

    const startIndex = (currentPage - 1) * itemsPerPage;
    const currentData = data.slice(startIndex, startIndex + itemsPerPage);

    // Generate pagination buttons
    const pageNumbers = [];
    const visiblePages = 5;
    let startPage = Math.max(1, currentPage - Math.floor(visiblePages / 2));
    let endPage = Math.min(totalPages, startPage + visiblePages - 1);

    if (endPage - startPage + 1 < visiblePages) {
        startPage = Math.max(1, endPage - visiblePages + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
    }

    let navigate = useNavigate();
    const openLiveReport = (e, id, title) => {
        e.preventDefault();
        navigate(`/matchscreen/${sport}/${id}/${title}`);
        // navigate(`/livematchscreen/${sport}/${id}/${title}`);
    };

    return (
        <div className="matches-table-root">
            {/* <div className="matches-table-container"> */}
            <table className="matches-table-custom">
                <thead>
                    <tr>
                        {columns.map((column, index) => (
                            <th className="matches-table-header-cell-grey" key={index}>{column}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {currentData.map((row, rowIndex) => (
                        <tr key={rowIndex} className="matches-table-row">
                            <td className="matches-table-custom-td">
                                {row.id}
                            </td>
                            <td className="matches-table-custom-td">
                                {row.id}
                            </td>
                            <td className="matches-table-custom-td-link">
                                <Link to={`/matchesdashboard/${sport}/${row.id}/${row.name}`} style={{ textDecoration: "none", color: "#337ab7" }}>
                                    {row.name}
                                </Link>
                            </td>
                            <td className="matches-table-custom-td">
                                {sport && sport.toUpperCase()}
                            </td>
                            <td className="matches-table-custom-td">
                                <img src={Clock_Icon} className="clock-icon" alt="Clock Icon" />{row.openDate}
                            </td>
                            {!liveMatches && <td className="matches-table-custom-td">{row.winner}</td>}
                            <td className="matches-table-custom-td">
                                {!liveMatches ? <label className={row.profitLoss < 0 ? "label-loss" : "label-profit"}>{row.profitLoss.toFixed(1)}</label> :
                                    <button className="cric-board-buttons board-buttons-nav-bar-dark-small2-live-report"
                                        style={{ marginLeft: "5px", fontSize: "14px", fontWeight: "400" }}
                                        onClick={(e) => openLiveReport(e, row.id, row.name)}>
                                        Live Report
                                    </button>}
                            </td>
                            {/* {columns.map((column, colIndex) => (
                <td key={colIndex}>
                  {column === 'name' ? (
                    <Link to={`/profile/${row[column]}`} className="name-link">
                      {row[column]}
                    </Link>
                  ) : (
                    row[column]
                  )}
                </td>
              ))} */}
                        </tr>
                    ))}
                </tbody>
            </table>
            {/* </div> */}
            {currentData && <div className="pagination">
                <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    className="agent-table-pagination-prev-button"
                    disabled={currentPage === 1}
                >
                    Previous
                </button>
                {startPage > 1 && <span className="pagination-ellipsis">...</span>}
                {pageNumbers.map((page) => (
                    <button
                        key={page}
                        onClick={() => handlePageChange(page)}
                        className={`matches-table-pagination-button ${currentPage === page ? 'active' : ''}`}
                    >
                        {page}
                    </button>
                ))}
                {endPage < totalPages && <span className="pagination-ellipsis">...</span>}
                <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    className="agent-table-pagination-next-button"
                    disabled={currentPage === totalPages}
                >
                    Next
                </button>
            </div>}
        </div>
    );
};

export default MatchesTable;
