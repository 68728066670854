import React, { useState, useEffect } from "react";
import "../../index.css";
import { useDispatch, useSelector } from "react-redux";
import { clearMessage } from "../../actions/message";
import { httpHelpers } from "../../services/httpHelpers";

const DownlineBalancePopup = ({ role, logout, child, closeDownlineBalancePopup }) => {

    let getDownlineBalance = "beta/getDownLineBalance?userId=" + child.userId;
    const { message } = useSelector(state => state.message);
    const dispatch = useDispatch();
    const api = httpHelpers();
    const [downlineBalance, setDownlineBalance] = useState("...");

    const fetchDownlineBalance = () => {
        api
            .get(`${getDownlineBalance}`)
            .then(res => {
                console.log("get downline balance res", res);
                if (res && res.data) {
                    setDownlineBalance(res.data);
                } else {
                    setDownlineBalance(null);
                }
            })

            .catch(err => {
                console.log("error error", err);
                if (err) {
                    if (err.data) {
                        if (err.data.status && err.data.status === 401) {
                            logout();
                        }
                    } else if (err.response) {
                        if (err.response.status && err.response.status === 401) {
                            logout();
                        }
                    }
                }
            });
    };

    useEffect(() => {
        dispatch(clearMessage());
        fetchDownlineBalance();
    }, []);

    return (
        <div className="cric-agent-downline-balance-popup-container">
            <div className="cric-agent-downline-balance-popup-body">
                <label className="downline-balance-popup-title">Downline Balance of - {child.userId}</label>
                <label className="downline-balance-popup-value">{downlineBalance}</label>
            </div>
            {message && <span style={{ color: "red" }}>{message}</span>}
            <div className="cric-agent-downline-balance-popup-close-button">
                <button
                    className="board-buttons board-buttons-nav-bar-dark-small-downline-balance-close"
                    onClick={(e) => closeDownlineBalancePopup(e)} >OK</button>
            </div>
        </div>
    );
};

export default DownlineBalancePopup;