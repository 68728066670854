import React, { useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import './css/DateRangePicker.css';
import { Input } from 'rsuite';

const required = (value) => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                This field is required!
            </div>
        );
    }
};

const SearchBar = ({ setId, setError, isSmallScreen }) => {

    const [userId, setUserId] = useState();

    const handleSearchClick = (e) => {
        if (userId) {
            setId(userId.toUpperCase());
        } else {
            setError("Please Enter User Id")
        }
    };

    const onChangeUserId = (e) => {
        setUserId(e.toUpperCase());
    };

    return (
        <div className="date-range-form-container">
            <div className="date-range-picker-container">
                <Input
                    placeholder="User Id"
                    id="uid"
                    type="text"
                    className="form-control"
                    name="uid"
                    maxLength="20"
                    value={userId || ""}
                    onChange={(e) => onChangeUserId(e)}
                    validations={[required]}
                    style={{ fontSize: "14px", border: "1px solid #676a6c", marginTop: "2px" }}
                />
            </div>
            {/* <div style={{ position: "fixed", zIndex: "1000", marginLeft: isSmallScreen? "240px" : "280px", marginTop: "2px"}}> */}
            <div style={{ marginLeft: isSmallScreen ? "10px" : "20px", marginTop: "2px", textAlign: "left" }}>
                <button className="cric-board-buttons board-buttons-nav-bar-dark-smaller"
                    style={{ marginLeft: "5px", height: isSmallScreen ? "34px" : "37px" }}
                    onClick={(e) => handleSearchClick(e)}>
                    Search
                </button>
            </div>
        </div>
    );
};

export default SearchBar;