import React, { useState, useRef, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate, useLocation } from 'react-router-dom';

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail, isMobilePhone } from "validator";
import TokenService from "../../services/token-service";
import { register, logout, editAgent, editUser } from "../../actions/auth";
import "../../index.css";
import { updateBalance } from "../../ParvatiApp.js";
import { clearMessage } from "../../actions/message";
import ToggleButton from "react-toggle-button";
import TableTitle from "./customized/TableTitle.js";

const required = (value) => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                This field is required!
            </div>
        );
    }
};

const validEmail = (value) => {
    if (!isEmail(value)) {
        return (
            <div className="alert alert-danger" role="alert">
                This is not a valid email.
            </div>
        );
    }
};

const vusername = (value) => {
    const re = /^\S*$/;
    if (!re.test(value)) {
        return (
            <div className="alert alert-danger" role="alert">
                User Name can not contain spaces.
            </div>
        );
    } else if (value.length < 3 || value.length > 20) {
        return (
            <div className="alert alert-danger" role="alert">
                The username must be between 3 and 20 characters.
            </div>
        );
    }
};

const vpassword = (value) => {
    if (value.length < 6 || value.length > 40) {
        return (
            <div className="alert alert-danger" role="alert">
                The password must be between 6 and 40 characters.
            </div>
        );
    }
};

const validPhone = (value) => {
    console.log(value)
    if (!isMobilePhone(value) || value.length < 10) {
        return (
            <div className="alert alert-danger" role="alert">
                This is not a valid phone.
            </div>
        );
    }
};

const validCommission = (value) => {
    console.log(value)
    if (value > 100 || value < 0) {
        return (
            <div className="alert alert-danger" role="alert">
                Please enter in valid range (0 - 100).
            </div>
        );
    }
};

// const validBalance = (value) => {
//   console.log(value)
//   if (!isMobilePhone(value) || value.length < 10) {
//     return (
//       <div className="alert alert-danger" role="alert">
//         This is not a valid phone.
//       </div>
//     );
//   }
// };

const Update = ({ role, logout, isSmallScreen }) => {

    const user = TokenService.getUser();

    if (user === null) {
        logout();
    }
    const { state } = useLocation();

    if (state === null) {
        logout();
    }
    const { child } = state ? state : "";
    console.log("edit", child);

    let formHeader = "User";
    let regType = "User ";
    let registerType = "user";
    if (child) {
        if (child.entityType.includes("submaster")) {
            formHeader = "Sub Master";
            regType = "SM ";
            registerType = "submaster";
        } else if (child.entityType.includes("subcompany")) {
            formHeader = "Sub Company";
            regType = "SC ";
            registerType = "subcompany";
        } else if (child.entityType.includes("superstockist")) {
            formHeader = "Super Stockist";
            regType = "SST ";
            registerType = "superstockist";
        } else if (child.entityType.includes("stockist")) {
            formHeader = "Stockist";
            regType = "Stockist ";
            registerType = "stockist";
        } else if (child.entityType.includes("agent")) {
            formHeader = "Agent";
            regType = "Agent ";
            registerType = "agent";
        }
    }

    const form = useRef();
    const checkBtn = useRef();

    const [userId, setUserId] = useState(child ? child.userId : "");
    const [firstName, setFirstName] = useState(child ? child.firstName : "");
    const [lastName, setLastName] = useState(child ? child.lastName : "");
    const [currentLimit, setCurrentLimit] = useState(child ? child.balance : 0);
    // const [password, setPassword] = useState("");
    // const [confirmPassword, setConfirmPassword] = useState("");
    const [yourPassword, setYourPassword] = useState("");
    const [share, setShare] = useState(child ? child.share : "");
    const [openingBalance, setOpeningBalance] = useState(child ? child.openingBalance : "");
    const [creditReference, setCreditReference] = useState(child ? child.creditReference : "");
    const [exposureLimit, setExposureLimit] = useState(child ? child.exposureLimit : "");
    const [status, setStatus] = useState(child ? child.accountStatus : "");
    const [successful, setSuccessful] = useState(false);
    const [newCreatedUserName, setNewCreatedUserName] = useState(null);
    const [showError, setShowError] = useState(true);

    const [agentBlocked, setAgentBlocked] = useState(status === "BLOCKED" ? true : false);
    const [betsBlocked, setBetsBlocked] = useState(status === "BETSBLOCKED" ? true : false);

    const borderRadiusStyle = { borderRadius: 2 }

    useEffect(() => {
        dispatch(clearMessage());
    }, []);

    const { message } = useSelector(state => state.message);
    const dispatch = useDispatch();
    let navigate = useNavigate();

    const onChangeFirstName = (e) => {
        const fname = e.target.value;
        setFirstName(fname);
    };

    const onChangeLastName = (e) => {
        const lname = e.target.value;
        setLastName(lname);
    };

    const onChangeCurrentLimit = (e) => {
        const newValue = e.target.value;
        if (newValue === '' || (/^\d+$/.test(newValue))) {
            if (Number(newValue) > Number(user.balance)) {
                setCurrentLimit(user.balance);
            } else if (Number(newValue) < 0) {
                setCurrentLimit(0);
            } else {
                setCurrentLimit(newValue);
            }
        } else {
            setCurrentLimit(child.balance);
        }
    };

    const cancel = (e) => {
        e.preventDefault();
        navigate(-1);
    }

    const handleEdit = (e) => {
        e.preventDefault();
        setSuccessful(false);
        form.current.validateAll();

        if (checkBtn.current.context._errors.length === 0) {
            console.log("register user", role);
            let status = "ACTIVE";
            if (betsBlocked) {
                status = "BETSBLOCKED";
            }
            if (agentBlocked) {
                status = "BLOCKED";
            }

            dispatch(editAgent(userId, status, child.matchCommission, child.sessionCommission, child.entityType))
                .then((data) => {
                    console.log("edit agent data: ", data);
                    if (data.status === 401) {
                        if (data.data === "Wrong password") {
                            setSuccessful(false);
                        } else {
                            logout();
                        }
                    } else if (data.data === "Admin balance not sufficient" || data.data === "Insuffcient Balance") {
                        setSuccessful(false);
                    } else if (data.status === 200) {
                        setSuccessful(true);
                        let eType = data.data.entityType;
                        let msg = "User " + data.data.userId + " is Updated Successfully!";
                        if (eType.includes("user")) {
                            // navigate(`/showuser/${data.data.userId}/`);
                            navigate('/clients', { state: { msg } });
                        } else if (eType.includes("submaster")) {
                            // navigate(`/showsc/${data.data.userId}/`);
                            navigate('/showsm', { state: { msg } });
                        } else if (eType.includes("subcompany")) {
                            // navigate(`/showsc/${data.data.userId}/`);
                            navigate('/showsc', { state: { msg } });
                        } else if (eType.includes("superstockist")) {
                            // navigate(`/showsst/${data.data.userId}/`);
                            navigate('/showsst', { state: { msg } });
                        } else if (eType.includes("stockist")) {
                            // navigate(`/showst/${data.data.userId}/`);
                            navigate('/showst', { state: { msg } });
                        } else if (eType.includes("agent")) {
                            // navigate(`/showagent/${data.data.userId}/`);
                            navigate('/showagent', { state: { msg } });
                        }
                    }
                })
                .catch(() => {
                });

            // if (child.userType === "user") {
            //     dispatch(editUser(userId, firstName, lastName, blockedStatus))
            //         .then((data) => {
            //             console.log("edit agent data: ", data);
            //             if (data.status === 401) {
            //                 if (data.data === "Wrong password") {
            //                     setSuccessful(false);
            //                 } else {
            //                     logout();
            //                 }
            //             } else if (data.data === "Admin balance not sufficient" || data.data === "Insuffcient Balance") {
            //                 setSuccessful(false);
            //             } else if (data.status === 200) {
            //                 setSuccessful(true);
            //             }
            //         })
            //         .catch(() => {
            //         });
            // } else {
            //     dispatch(editAgent(userId, firstName, lastName, share, blockedStatus, yourPassword, creditReference))
            //         .then((data) => {
            //             console.log("edit agent data: ", data);
            //             if (data.status === 401) {
            //                 if (data.data === "Wrong password") {
            //                     setSuccessful(false);
            //                 } else {
            //                     logout();
            //                 }
            //             } else if (data.data === "Admin balance not sufficient" || data.data === "Insuffcient Balance") {
            //                 setSuccessful(false);
            //             } else if (data.status === 200) {
            //                 setSuccessful(true);
            //             }
            //         })
            //         .catch(() => {
            //         });
            // }
        }
    };

    return (
        <div className="cric-register-top-container">
            <div className="register-container">
                <div className="cric-card">
                    {!successful && <div>
                        {/* <label className="cric-register-header">Update</label> */}
                        <TableTitle
                            text={"Edit " + formHeader}
                            color="#ffffff"
                            fontSize="14px"
                            textAlign="left"
                            width="100%"
                            height="42px"
                            marginLeft="0px"
                            marginRight="0px"
                            paddingLeft="10px"
                        />
                    </div>}
                    <div className="cric-card-container">
                        {/* <img
          src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
          alt="profile-img"
          className="profile-img-card"
        /> */}
                        {newCreatedUserName && <label style={{ fontSize: "30px", fontWeight: "bold" }}>{newCreatedUserName}</label>}

                        <Form onSubmit={handleEdit} ref={form}>
                            {!successful && (
                                <div>
                                    {/* <div className="form-group">
                    <div className="div-label-form">
                        <label className="label-form" htmlFor="username">User Name</label>
                    </div>
                <Input
                  id="reg_username"
                  type="text"
                  className="form-control"
                  name="username"
                  value={userName}
                  onChange={onChangeUserName}
                  validations={!successful && [required]}
                />
              </div> */}
                                    <div className="form-group">
                                        <div className="cric-form-label-input-container" style={{ paddingTop: isSmallScreen ? "10px" : "0px" }}>
                                            <div className="cric-div-label-form">
                                                <label className="label-form" htmlFor="userName">User Id</label>
                                            </div>
                                            <div className="cric-div-input-form">
                                                <Input
                                                    id="reg_username"
                                                    type="text"
                                                    className="form-control"
                                                    name="username"
                                                    value={child.userId}
                                                    disabled
                                                    style={{ backgroundColor: "#feefee" }}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-update-dotted-separator"></div>
                                    </div>
                                    <div className="form-group">
                                        <div className="cric-form-label-input-container">
                                            <div className="cric-div-label-form">
                                                <label className="label-form" htmlFor="fname">First Name</label>
                                            </div>
                                            <div className="cric-div-input-form">
                                                <Input
                                                    id="reg_fname"
                                                    type="text"
                                                    className="form-control"
                                                    name="fname"
                                                    maxLength="20"
                                                    value={firstName}
                                                    onChange={onChangeFirstName}
                                                    validations={!successful && [required]}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-update-dotted-separator"></div>
                                    </div>
                                    <div className="form-group">
                                        <div className="cric-form-label-input-container">
                                            <div className="cric-div-label-form">
                                                <label className="label-form" htmlFor="lname">Last Name</label>
                                            </div>
                                            <div className="cric-div-input-form">
                                                <Input
                                                    id="reg_lname"
                                                    type="text"
                                                    className="form-control"
                                                    name="lname"
                                                    maxLength="20"
                                                    value={lastName}
                                                    onChange={onChangeLastName}
                                                    validations={!successful && [required]}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-update-dotted-separator"></div>
                                    </div>
                                    <div className="form-group">
                                        <div className="cric-form-label-input-container">
                                            <div className="cric-div-label-form">
                                                <label className="label-form" htmlFor="openingbalance">Current Limit</label>
                                            </div>
                                            <div className="cric-div-input-form">
                                                <Input
                                                    placeholder="0"
                                                    type="tel"
                                                    id="reg_climit"
                                                    className="form-control"
                                                    name="openingbalance"
                                                    value={currentLimit}
                                                    onChange={onChangeCurrentLimit}
                                                    validations={!successful && [required]}
                                                    style={{ fontSize: "14px", backgroundColor: "#feefee" }}
                                                    disabled
                                                // maxLength={(user.balance.toString().length).toString()}
                                                />
                                                <div className="div-note">
                                                    <label className="label-note">Note :</label>
                                                    <div className="div-note-1">
                                                        <label>Initial Balance can be set from 0 to</label>
                                                    </div>
                                                    <div className="div-note-2">
                                                        <label>{user.balance}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-update-dotted-separator"></div>
                                    </div>
                                    <div className="form-group">
                                        <div className="cric-form-label-input-container">
                                            <div className="cric-div-label-form">
                                                <label className="label-form" htmlFor="share">My Match Share</label>
                                            </div>
                                            <div className="cric-div-input-form">
                                                <Input
                                                    type="tel"
                                                    id="reg_mmshare"
                                                    className="form-control"
                                                    name="share"
                                                    value={child.userId.includes("user") ? user.share : child.share}
                                                    style={{ fontSize: "14px", backgroundColor: "#feefee" }}
                                                    disabled
                                                />
                                                <div className="div-note">
                                                    <label className="label-note">Note :</label>
                                                    <div className="div-note-1">
                                                        <label>My Match Share can be set from 0 to</label>
                                                    </div>
                                                    <div className="div-note-2">
                                                        <label>{user.share}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-update-dotted-separator"></div>
                                    </div>
                                    {child.userId.includes("user") &&
                                        <div className="form-group">
                                            <div className="cric-form-label-input-container">
                                                <div className="cric-div-label-form">
                                                    <label className="label-form" htmlFor="share">Match Share</label>
                                                </div>
                                                <div className="cric-div-input-form">
                                                    <input
                                                        placeholder="0"
                                                        type="tel"
                                                        id="reg_amshare"
                                                        className="form-control"
                                                        name="ams"
                                                        value={child.share}
                                                        disabled
                                                        style={{ backgroundColor: "#feefee", fontSize: "14px" }}
                                                    />
                                                    <div className="div-note">
                                                        <label className="label-note">Note :</label>
                                                        <div className="div-note-1">
                                                            <label>Match Share can be set from 0 to</label>
                                                        </div>
                                                        <div className="div-note-2">
                                                            <label>{user.share - child.share}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>}
                                    <div className="form-group">
                                        <div className="cric-form-label-input-container">
                                            <div className="cric-div-label-form">
                                                <label className="label-form" htmlFor="share">Match Commission</label>
                                            </div>
                                            <div className="cric-div-input-form">
                                                <Input
                                                    placeholder="0"
                                                    type="tel"
                                                    id="reg_amc"
                                                    className="form-control"
                                                    name="amc"
                                                    value={child.matchCommission}
                                                    disabled
                                                    style={{ backgroundColor: "#feefee", fontSize: "14px" }}
                                                />
                                                <div className="div-note">
                                                    <label className="label-note">Note :</label>
                                                    <div className="div-note-1">
                                                        <label>Match Commission can be set from 0 to</label>
                                                    </div>
                                                    <div className="div-note-2">
                                                        <label>{user.matchCommission}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-update-dotted-separator"></div>
                                    </div>
                                    <div className="form-group">
                                        <div className="cric-form-label-input-container">
                                            <div className="cric-div-label-form">
                                                <label className="label-form" htmlFor="share">Session Commission</label>
                                            </div>
                                            <div className="cric-div-input-form">
                                                <Input
                                                    placeholder="0"
                                                    type="tel"
                                                    id="reg_asc"
                                                    className="form-control"
                                                    name="asc"
                                                    value={child.sessionCommission}
                                                    disabled
                                                    style={{ backgroundColor: "#feefee", fontSize: "14px" }}
                                                />
                                                <div className="div-note">
                                                    <label className="label-note">Note :</label>
                                                    <div className="div-note-1">
                                                        <label>Session Commission can be set from 0 to</label>
                                                    </div>
                                                    <div className="div-note-2">
                                                        <label>{user.sessionCommission}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-update-dotted-separator"></div>
                                    </div>
                                    <div className="form-group">
                                        <div className="cric-form-label-input-container">
                                            <div className="cric-div-label-form">
                                                <label className="label-form" htmlFor="agentblocked">
                                                    Agent Blocked
                                                </label>
                                            </div>
                                            <div className="cric-div-input-form">
                                                <div className="cric-toggle-button-container">
                                                    <ToggleButton
                                                        value={agentBlocked}
                                                        inactiveLabel={"Off"}
                                                        activeLabel={"On"}
                                                        thumbStyle={borderRadiusStyle}
                                                        trackStyle={borderRadiusStyle}
                                                        onToggle={value => {
                                                            setAgentBlocked(!value);
                                                        }} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-update-dotted-separator"></div>
                                    </div>
                                    <div className="form-group">
                                        <div className="cric-form-label-input-container">
                                            <div className="cric-div-label-form">
                                                <label className="label-form" htmlFor="betsblocked">
                                                    Bets Blocked
                                                </label>
                                            </div>
                                            <div className="cric-div-input-form">
                                                <div className="cric-toggle-button-container">
                                                    <ToggleButton
                                                        value={betsBlocked}
                                                        inactiveLabel={"Off"}
                                                        activeLabel={"On"}
                                                        thumbStyle={borderRadiusStyle}
                                                        trackStyle={borderRadiusStyle}
                                                        disabled={agentBlocked}
                                                        onToggle={value => {
                                                            setBetsBlocked(!value);
                                                        }} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-update-dotted-separator"></div>
                                    </div>
                                    {/* <div className="form-group" style={{ marginTop: "30px" }}>
                                        <button className="board-buttons board-buttons-nav-bar-dark-small">Save Changes</button>
                                    </div> */}
                                    <div className="form-buttons-cancel-submit-edit">
                                        <button className="board-buttons board-buttons-nav-bar-dark-smaller-white"
                                            onClick={(e) => cancel(e)}>Cancel</button>
                                        <div className="form-group">
                                            <button className="board-buttons board-buttons-nav-bar-dark-small-save-changes">Save Changes</button>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <CheckButton style={{ display: "none" }} ref={checkBtn} />
                        </Form>
                    </div>

                    {(message || successful) && (<div className="form-group">
                        <div className={successful ? "alert alert-success" : "alert alert-danger"} role="alert">
                            {message ? message : (userId + " Details Updated Successfully!")}
                        </div>
                    </div>)}
                </div>
            </div>
        </div>
    );
};

export default Update;