import React, { useCallback, useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import TokenService from "../../services/token-service";
import whatsapp from '../../assets/whatsapp_48.png';
import '../../App.css';
import './css/Home.css';
import { httpHelpers } from "../../services/httpHelpers";
import { Container, Card, Typography, CardContent, Button } from "@mui/material";
import MarqeeNavPath from "./MarqeeNavPath";
import SANotificationPopup from "./SANotificationPopup";
import AgentNotificationPopup from "./AgentNotificationPopup";
import Modal from './Modal';
import TableTitle from "./customized/TableTitle";

const CricHome = ({ role, logout, marqee, navPath, isSmallScreen }) => {
    console.log("dashboard", role);
    const currentPage = "Home";
    const { state } = useLocation();
    const { notificationMessage } = state ? state : "";
    console.log("dashboard", notificationMessage);
    const user = TokenService.getUser();
    // if (!user) {
    //     logout();
    // }
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const [myUserName, setMyUserName] = useState(user ? user.userId : "");
    const [myName, setMyName] = useState(user ? user.firstName + " " + user.lastName : "");
    const [myLevel, setMyLevel] = useState(user ? user.entityType : "");
    const [myFixLimit, setMyFixLimit] = useState(user ? user.balance : "");
    const [companyContact, setCompanyContact] = useState(user ? user.parentId : "");
    const [maximumMyShare, setMaximumMyShare] = useState(user ? user.share : "");
    const [minimumCompanyShare, setMinimumCompanyShare] = useState(user ? 100 - user.share : "");
    const [matchCommission, setMatchCommission] = useState(user ? user.matchCommission : "");
    const [sessionCommission, setSessionCommission] = useState(user ? user.sessionCommission : "");
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
        console.log("rules", "useEffect", notificationMessage);
        const isNotificationPopupDisplayed = TokenService.isNotificationPopupDisplayed(user && user.userId);
        console.log("rules", isNotificationPopupDisplayed);
        if (!isNotificationPopupDisplayed && notificationMessage) {
            setModalOpen(true);
        }
    }, [notificationMessage]);

    let url = "alpha/getBalance";
    const api = httpHelpers();

    const fetchBalance = () => {
        setIsLoading(true);
        api
            .get(`${url}`)
            .then(res => {
                console.log("fetch balance res", res.data)
                let role = "admin";
                let temp = TokenService.getUser();
                temp.balance = res.data;
                TokenService.setUser(temp);
                setIsLoading(false); // Set loading state back to false when done fetching
            })
            .catch(err => {
                console.log("error error", err);
                if (err) {
                    if (err.data) {
                        if (err.data.status && err.data.status === 401) {
                            logout();
                        }
                    } else if (err.response) {
                        if (err.response.status && err.response.status === 401) {
                            logout();
                        }
                    }
                }
            })
    }

    const [isModalOpen, setModalOpen] = useState(false);
    const closeAgentNotificationPopup = (e) => {
        e.preventDefault();
        TokenService.notificationPopupDisplayed(user && user.userId);
        setModalOpen(false);
    };

    return (
        <div className="cric-home-root">
            {/* <MarqeeNavPath marqee={marqee} navPath={navPath} currentPage={currentPage} /> */}
            {isModalOpen && (
                <Modal onClose={() => setModalOpen(false)} isSmallScreen={isSmallScreen}>
                    <AgentNotificationPopup role={role} logout={logout} notificationMessage={notificationMessage} closeAgentNotificationPopup={closeAgentNotificationPopup} />
                </Modal>
            )}
            <div className="home-top-container">
                <div className="home-container">
                    <div>
                        <div className="home-profile-row">
                            <div className="home-profile-content-container">
                                {/* <div className="home-profile-title-container">
                                    <label>MY USERNAME</label>
                                </div> */}
                                <TableTitle
                                    text="MY USERNAME"
                                    color="#ffffff"
                                    fontSize="14px"
                                    textAlign="left"
                                    width="100%"
                                    height="40px"
                                    marginLeft="0px"
                                    marginRight="0px"
                                />
                                <div className="home-profile-detail-container">
                                    <div>
                                        <label className="home-profile-detail-label">{myUserName}</label>
                                    </div>
                                    <div>
                                        <label className="home-profile-detail-sub-label">{myName}</label>
                                    </div>
                                </div>
                            </div>
                            <div className="home-profile-content-container">
                                {/* <div className="home-profile-title-container">
                                    <label>MY LEVEL</label>
                                </div> */}
                                <TableTitle
                                    text="MY LEVEL"
                                    color="#ffffff"
                                    fontSize="14px"
                                    textAlign="left"
                                    width="100%"
                                    height="40px"
                                    marginLeft="0px"
                                    marginRight="0px"
                                />
                                <div className="home-profile-detail-container">
                                    <div>
                                        <label className="home-profile-detail-label">{myLevel}</label>
                                    </div>
                                </div>
                            </div>
                            <div className="home-profile-content-container">
                                {/* <div className="home-profile-title-container">
                                    <label>MY FIX LIMIT</label>
                                </div> */}
                                <TableTitle
                                    text="MY FIX LIMIT"
                                    color="#ffffff"
                                    fontSize="14px"
                                    textAlign="left"
                                    width="100%"
                                    height="40px"
                                    marginLeft="0px"
                                    marginRight="0px"
                                />
                                <div className="home-profile-detail-container">
                                    <div>
                                        <label className="home-profile-detail-label">{myFixLimit}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="home-profile-row">
                            <div className="home-profile-content-container">
                                {/* <div className="home-profile-title-container">
                                    <label>Company Contact</label>
                                </div> */}
                                <TableTitle
                                    text="Company Contact"
                                    color="#ffffff"
                                    fontSize="14px"
                                    textAlign="left"
                                    width="100%"
                                    height="40px"
                                    marginLeft="0px"
                                    marginRight="0px"
                                />
                                <div className="home-profile-detail-container">
                                    <div>
                                        <label className="home-profile-detail-label">{companyContact}</label>
                                    </div>
                                </div>
                            </div>
                            <div className="home-profile-content-container">
                                {/* <div className="home-profile-title-container">
                                    <label>Maximum My Share</label>
                                </div> */}
                                <TableTitle
                                    text="Maximum My Share"
                                    color="#ffffff"
                                    fontSize="14px"
                                    textAlign="left"
                                    width="100%"
                                    height="40px"
                                    marginLeft="0px"
                                    marginRight="0px"
                                />
                                <div className="home-profile-detail-container">
                                    <div>
                                        <label className="home-profile-detail-label">{maximumMyShare}%</label>
                                    </div>
                                </div>
                            </div>
                            <div className="home-profile-content-container">
                                {/* <div className="home-profile-title-container">
                                    <label>Minimum Company Share</label>
                                </div> */}
                                <TableTitle
                                    text="Maximum Company Share"
                                    color="#ffffff"
                                    fontSize="14px"
                                    textAlign="left"
                                    width="100%"
                                    height="40px"
                                    marginLeft="0px"
                                    marginRight="0px"
                                />
                                <div className="home-profile-detail-container">
                                    <div>
                                        <label className="home-profile-detail-label">{minimumCompanyShare}%</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="home-profile-row-for-2">
                            <div className="home-profile-content-container-for-2">
                                {/* <div className="home-profile-title-container">
                                    <label>Match Commission</label>
                                </div> */}
                                <TableTitle
                                    text="Match Commission"
                                    color="#ffffff"
                                    fontSize="14px"
                                    textAlign="left"
                                    width="100%"
                                    height="40px"
                                    marginLeft="0px"
                                    marginRight="0px"
                                />
                                <div className="home-profile-detail-container">
                                    <div>
                                        <label className="home-profile-detail-label">{matchCommission}</label>
                                    </div>
                                </div>
                            </div>
                            <div className="home-profile-content-container-for-2">
                                {/* <div className="home-profile-title-container">
                                    <label>Session Commission</label>
                                </div> */}
                                <TableTitle
                                    text="Session Commission"
                                    color="#ffffff"
                                    fontSize="14px"
                                    textAlign="left"
                                    width="100%"
                                    height="40px"
                                    marginLeft="0px"
                                    marginRight="0px"
                                />
                                <div className="home-profile-detail-container">
                                    <div>
                                        <label className="home-profile-detail-label">{sessionCommission}</label>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="home-profile-content-container" style={{ marginBottom: "55px" }}>
                                <div className="home-profile-title-container">
                                    <label>P & L</label>
                                </div>
                                <div className="home-profile-detail-container">
                                    <div>
                                        <label className="home-profile-detail-label">{refPL}</label>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CricHome;
